<div class="ng-doc-code-header" *ngIf="hasHeader">
  <span class="ng-doc-code-file-name" ng-doc-text>
    <ng-doc-icon [customIcon]="icon" *ngIf="icon"></ng-doc-icon>
    {{ name }}
  </span>
</div>
<div class="ng-doc-code-body">
  @if (html) {
    <div class="ng-doc-code-wrapper" [ngDocPageProcessor]="html | ngDocSanitizeHtml"></div>
  } @else {
    <div class="ng-doc-code-wrapper">
      <ng-content></ng-content>
    </div>
  }

  <ng-doc-copy-button
    class="ng-doc-copy-button"
    [text]="this.codeElement?.textContent ?? ''"
    *ngIf="copyButton">
    <ng-doc-icon icon="copy"></ng-doc-icon>
  </ng-doc-copy-button>
</div>
