<label [ng-doc-label]="option?.hideLabel ? '' : labelContent">
	<ng-template #labelContent>
		<span
			[ngDocTooltip]="tooltipTemplate"
			[canOpen]="!!tooltipContent"
			[positions]="['left-center', 'top-right', 'bottom-right']"
			>{{ name }}</span
		>
		<ng-template #tooltipTemplate>
			<div [innerHTML]="tooltipContent | ngDocSanitizeHtml"></div>
		</ng-template>
	</ng-template>
	<ng-container #propertyOutlet></ng-container>
</label>
